import { css, styled } from 'styled-components';

const isNextJS =
  typeof window === 'undefined' ||
  typeof document === 'undefined' ||
  !!document.getElementById('__next');

export type HeaderStyledProp =
  | keyof HeaderNav__Link_StyledProps
  | keyof HeaderContainer__StyledProps;

type HeaderContainer__StyledProps = { menuOpen: boolean; scrolling: boolean };

//
// Main <header> element
//
export const Header__Container_ = styled.header<HeaderContainer__StyledProps>`
  background: ${(props) =>
    props.menuOpen ? 'rgba(255, 255, 255, 0.95)' : '#ffffff'};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 100;

  .HeaderMenuDesktop {
    display: none;
  }

  @media screen and (min-width: 400px) {
    padding: 0 1rem;
  }
  @media (min-width: 992px) {
    padding: ${(props) => (props.scrolling ? '0 1rem' : '1rem')};
    // Animate the resizing of the header based on scroll position
    transition: padding 0.2s ease-in-out;

    .HeaderMenuMobile {
      display: none;
    }
    .HeaderMenuDesktop {
      display: grid;
    }
  }

  @media (min-width: 1200px) {
    padding: ${(props) => (props.scrolling ? '0rem 5rem' : '1rem 5rem')};
  }
`;

export const Header__Spacer_ = styled.div`
  padding-top: 3rem;
  @media (min-width: 992px) {
    padding-top: 5rem;
  }
`;

//
// Logo section
//
export const HeaderLogo__Link_ = styled.a`
  grid-column: 1;
  display: inline-block;
  padding: 0.25rem;

  img {
    height: 2.5rem;
    margin: 0 auto;
    max-width: 100%;
    width: auto;
  }
`;

//
// Navigation section
//

export const HeaderNav__Container_ = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;

  @media (min-width: 992px) {
    flex-direction: row;
    padding: 0;
  }
  @media (min-width: 1200px) {
    gap: 2rem;
  }
`;

type HeaderNav__Link_StyledProps = {
  active?: boolean;
  hideOnMobile?: boolean;
};
export const HeaderNav__Link_ = styled.a<HeaderNav__Link_StyledProps>`
  color: ${({ active }) => (active ? '#936' : '#222')};
  line-height: 1;
  font-size: 16px;
  padding: 0;
  white-space: nowrap;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;
      @media (min-width: 992px) {
        display: inline;
      }
    `}
`;

export const HeaderMenuDesktop__Container_ = styled.div`
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
`;

export const HeaderMenuDesktop__Nav_ = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column: 2;
  justify-content: center;
`;

export const HeaderMenuMobile__Nav_ = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const Header__BuildAFormButton_ = styled.a`
  align-items: center;
  background: #993366 linear-gradient(90deg, #993366 0%, #5588dd 100%);
  border-radius: 4px;
  border: 0;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-flex;
  ${isNextJS ? '' : `font-family: Lexend, sans-serif;`}
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  margin: 0.6rem 0.6rem;
  padding: 0.6rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
`;
